<template>
  <div>
    <b-button @click="$router.push('/permissoes/adicionar')" class="mb-2" variant="primary-custom">Adicionar</b-button>
    <Card>
      <TableList />
    </Card>
    <!-- {{ model }} -->

   
  </div>
</template>

<script>
import crypto from "crypto";
export default {
  data() {
    return {
      randomKey: "",
      model: ["6"]      
    };
  },
  components: {
    PermissionMenu: () => import("./components/PermissionMenus.vue"),
    TableList: () => import("./components/TableList.vue"),
  },
  computed:{
      nodes(){
          return this.$store.state.permissions.permissions
      }
  },
  methods: {
    teste(v) {
      console.log(v);
    },
  },
  mounted() {
    this.$store.dispatch("permissions/permissions_init");
    
    
  },
};
</script>